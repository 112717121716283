import React from 'react'
import Link from '../../common/Link'

const ServiceWebTags = () => (
  <section className="service-web bg-faded py-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Agiles</span>
            <br />
            <strong>Entwicklungsteam</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          Wir stellen immer sicher, dass wir die besten Leute für Ihr Projekt einsetzen,
           denn wir glauben, dass ein gut abgestimmtes Team eine Garantie für den Erfolg ist.
           Jede Projektgruppe besteht aus Entwicklern, Designern, UI-Experten und Frontend-Profis.
           Wenn Sie mehr erfahren möchten, 
            <Link to="/about/team/">{' '}lernen Sie unser Team kennen</Link>.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Web-Anwendungslösungen</span>
            <br />
            für Unternehmen
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="seo-p">
            Unser 
            <h2 className="seo-span">Web-Apps-Entwicklungsservice </h2>
            wird Ihnen helfen, Ihre Marketingziele zu erreichen, egal in welcher Branche Sie tätig sind: E-Learning, ERP, BPA, spzialen Medien, E-Commerce, Gaming, Innovation, Health-Tech oder Lifestyle.
          </div>
          <ul className="list-unstyled list-inline list-badges">
            <li>
              <span className="badge badge-light">.NET</span>
            </li>
            <li>
              <span className="badge badge-light">.NET MVC</span>
            </li>
            <li>
              <span className="badge badge-light">Ruby on Rails</span>
            </li>
            <li>
              <span className="badge badge-light">HTML5</span>
            </li>
            <li>
              <span className="badge badge-light">ExpressJS</span>
            </li>
            <li>
              <span className="badge badge-light">Zend</span>
            </li>
            <li>
              <span className="badge badge-light">ASO.NET</span>
            </li>
            <li>
              <span className="badge badge-light">Angular JS</span>
            </li>
            <li>
              <span className="badge badge-light">Prototype testing</span>
            </li>
            <li>
              <span className="badge badge-light">QA testing</span>
            </li>
            <li>
              <span className="badge badge-light">Responsive web design</span>
            </li>
            <li>
              <span className="badge badge-light">Support & Maintenance</span>
            </li>
            <li>
              <span className="badge badge-light">Static websites</span>
            </li>
            <li>
              <span className="badge badge-light">
                Mobile-friendly websites
              </span>
            </li>
            <li>
              <span className="badge badge-light">Service integrations</span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4" />
        <div className="col-sm-12 col-md-8" />
      </div>
    </div>
  </section>
)

export default ServiceWebTags
