import React from 'react'
import Link from '../../common/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const ServiceWebOffer = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Outsourcing der Entwicklung von Web-Apps</span>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
            Warum lohnt es sich, Projekte an Zaven auszulagern? Weil wir erfolgreich mit Kunden aus verschiedenen Teilen der Welt gearbeitet haben und wissen, wie wir sie durch den Prozess führen können: Von Vision-Entwicklung, Beratung, Prototyping, UX-Design, QA-Testing, Anwendungsentwicklung, Implementierung, RWD und Integration bis hin zu Wartung und Support.
          </p>
          <p>
          Unser Near-Sourcing-Team garantiert Effizienz und hohe Leistung zu Offshore-Preisen. Um herauszufinden, welches Modell für Sie ideal wäre, schauen Sie sich unseren Outsourcing-Leitfaden an.&nbsp;
            <Link to="/services/poland-software-outsourcing/">
              Outsourcing-Leitfaden an
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-sm-12 col-md-4">
          <div className="seo-h2 text-sm-center text-md-right">
            <span className="text-muted text-regular">
              <strong className="seo-regular">Unternehmensversprechen</strong> und
            </span>
            <br />

            <h3 className="seo-span">
              <strong>Software-Entwicklungszyklus</strong>
            </h3>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          In unserem Unternehmen glauben wir, dass Zufriedenheit und Vertrauen der Kern jeder langfristigen Geschäftspartnerschaft sind. Um eine reibungslose Zusammenarbeit zu gewährleisten, haben wir uns bei der Softwareentwicklung und -implementierung an Best Practices orientiert.
          </p>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Voll funktionsfähige und benutzerfreundliche Lösungen
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Geringe Kosten und ein gutes Preis-Leistungs-Verhältnis,
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Hohe Benutzerakzeptanz
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              <h4 className="seo-span">Agiler Entwicklungsprozess</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebOffer
