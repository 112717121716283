import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceWebCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4 col-lg-6">
          <div className="lead text-sm-center text-md-right">
            <h3 className="seo-span seo-strong text-muted">
            Maßgeschneiderte Web-Apps. 
            </h3>
            <br />
            <span className="text-service">
            Beste Softwarequalität zu&nbsp;
              <strong className="span-regular">Offshoring-Preisen</strong>
            </span>
            <br />
            <a
              href="#contact-partial"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
              className="btn btn-outline-info my-2"
              id="custom-web-app-talk-btn"
            >
              Schauen Sie sich unser Angebot an!
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="seo-p">
          Unsere <h4 className="seo-span">Entwickler</h4> sind sehr sachkundig und haben umfangreiche Erfahrung in der 
            <h3 className="seo-span">Entwicklung von kundenspezifischen Webanwendungen </h3> 
             für unsere Kunden aus der ganzen Welt.
             Wir bieten Backend-Upgrades und Cloud-Anbindungen. Darüber hinaus kann unser 
             Team das bestehende Frontend aktualisieren, was zu einer verbesserten Software-Geschwindigkeit führt.
             <h4 className="seo-span">Die Entwickler </h4> von Zaven sind stets effizient, kostengünstig und kundenorientiert.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebCta
